import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'manager-pnl',
  templateUrl: './z-a-manager-pnl.component.html',
  styleUrls: ['./z-a-manager-pnl.component.css']
})
export class managerPnl implements OnInit {

  @Input() aPanelData: any;

  constructor() { }

  ngOnInit() {
  }

}
