import { Injectable } from '@angular/core';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor( public http: HttpClient ) { }

  getJSON_GET(sUrlHost: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'text/plain'
      })
    };
    return this.http.get(`${sUrlHost}`, httpOptions);
  }

  getJSON_POST(sUrlHost: string, aPostData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'text/plain'
      })
    };
    const aPreparedData = new FormData();
    for (let sKey in aPostData) {
      aPreparedData.append(sKey, aPostData[sKey]);
    }
    return this.http.post(`${sUrlHost}`, aPreparedData);
  }
}
