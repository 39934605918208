import { Component, OnInit } from '@angular/core';

import { AppComponent } from '../app.component';

@Component({
  selector: 'app-x-footer',
  templateUrl: './x-footer.component.html',
  styleUrls: ['./x-footer.component.css']
})
export class XFooterComponent implements OnInit {

  curYear: number;
  langData: any;
  
  constructor( private app: AppComponent ) { 
    this.updateLanguage();
  }

  updateLanguage () {
    this.curYear=(new Date()).getFullYear();
    this.langData=this.app.langData;
  }

  ngOnInit() {
  }

}
