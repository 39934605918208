import { Component, OnInit } from '@angular/core';

import { AppComponent } from '../app.component';

import { Router, ActivatedRoute } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isNull } from 'util';

@Component({
  selector: 'app-x-header',
  templateUrl: './x-header.component.html',
  styleUrls: ['./x-header.component.css']
})
export class XHeaderComponent implements OnInit {

  curPage: string;
  element: HTMLElement;
  langs = [];
  selLang: string;
  langData: any;

  constructor( public app: AppComponent,
               private router: Router,
               private route: ActivatedRoute ) {  
    this.langs=this.app.langsAvailable;
    this.selLang=this.app.selLang;
    this.langData=this.app.langData;
    this.curPage=this.app.curPage;
  }  

  langChange(sSelectedLang: string) {
    this.app.selLang = sSelectedLang;
    this.app.browserLang = sSelectedLang;
    this.selLang = sSelectedLang;
    this.router.navigate([ sSelectedLang + "/" + this.router.url.substr(4)]);
    this.app.setCookie("selLang", this.selLang);
    this.app.langRequest();
    // Здесь лежит попытка поменять язык без включения окна ожидания
    // for (let i = 0; i < this.app.tempLang.length; i++) {
    //   var sLangCode: string = this.app.tempLang[i].code;
    //   if (this.selLang == sLangCode) {
    //     this.langData = this.app.tempLang[i].data;
    //     this.app.langData = this.langData;
    //     this.footer.updateLanguage();
    //     this.footer.ngOnInit();
    //     this.app.setTitle();
    //   }
    // }
  }

  topMenuClick(keyClick: string) {
    var sSelectedLang: string = this.app.getCookie("selLang");
    this.curPage = keyClick;
    this.router.navigate([sSelectedLang + "/" + this.curPage]);
    this.element = <HTMLElement> document.getElementById('navbarSupportedContent');
    this.element.className = this.element.className.replace('show','');
    var oMenuItems: HTMLCollectionOf<Element> = <HTMLCollectionOf<Element>> document.getElementsByClassName("header_menu");
    //console.log(oMenuItems);
    Array.prototype.forEach.call(oMenuItems, function (oMenuItem: HTMLElement) {
      oMenuItem.classList.remove("active");
    });
    var oActiveMenu: HTMLDivElement = <HTMLDivElement> document.getElementById("header_menu_" + this.curPage);
    if (!isNull(oActiveMenu)) {
      oActiveMenu.classList.add("active");
    }
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let url = this.route.snapshot.children[0].routeConfig.path.split("/:")[0]; 
      url = (url != "" ? url : "index");
      this.curPage=url;
    });
  }

}
