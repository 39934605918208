import { Component, OnInit, Input } from '@angular/core';

import { AppComponent } from '../app.component';

@Component({
  selector: 'wgt-carousel',
  templateUrl: './z-wgt-carousel.component.html',
  styleUrls: ['./z-wgt-carousel.component.css']
})
export class wgtCarousel implements OnInit {

  @Input() aWidgetParams: any;
  aCarouselLangData: any;

  constructor( public app: AppComponent ) {
    this.aCarouselLangData = app.langData.widgets.carousel;
   }

  ngOnInit() {
  }

}
