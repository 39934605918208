import { Component, OnInit, Input } from '@angular/core';

import { AppComponent } from '../app.component';
import { ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'pnl-high-title',
  templateUrl: './z-pnl-high-title.component.html',
  styleUrls: ['./z-pnl-high-title.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class pnlHighTitle implements OnInit {

  @Input() aPanelData: any;

  constructor( public app: AppComponent ) { }

  ngOnInit() {
  }

}
