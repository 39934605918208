import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppComponent } from '../app.component';

export interface AlertDialogData {
  title: string,
  msg: string,
  closeBtn: string, 
  type: number
}

@Component({
  selector: 'dlg-alert',
  templateUrl: './z-dlg-alert.component.html',
  styleUrls: ['./z-dlg-alert.component.css']
})
export class dlgAlert implements OnInit {

  constructor( public dialogRef: MatDialogRef<dlgAlert>,
               @Inject(MAT_DIALOG_DATA) public oData: AlertDialogData ) { }

  ngOnInit() {
  }

  closeAlert() {
    this.dialogRef.close();
  }

}
