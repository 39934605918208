import { Component, OnInit, Input } from '@angular/core';

import { AppComponent } from '../app.component';
import { ViewChild, ElementRef } from '@angular/core';

export class userFeedback {
  public sFName: string;
  public sLName: string;
  public sEmail: string;
  public sPhone: string;
  public sSubject: string;
  public sMessage: string;
}

@Component({
  selector: 'wgt-contactus',
  templateUrl: './z-wgt-contactus.component.html',
  styleUrls: ['./z-wgt-contactus.component.css']
})
export class wgtContactus implements OnInit {

  sCaptchaSiteKey: string;
  sCaptchaAnswer: string;
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  currentUsersFeedback = new userFeedback();
  @Input() aWidgetParams: any;
  aContactUsLangData: any;

  constructor( public app: AppComponent ) {
    this.aContactUsLangData = app.langData.widgets.contactus;
    this.sCaptchaSiteKey = "6LcfXGUcAAAAADwX_8ngOMUujXjXsvbUJPk6dp0t";
    this.sCaptchaAnswer = "";
  }

  addReCaptcha() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) { 
      //   obj.renderReCaptcha(); return;
      // }
      js = d.createElement(s); 
      js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?hl=" + obj.app.selLang + "&onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': this.sCaptchaSiteKey,
      'callback': (sReceivedResponse: string) => {
        this.sCaptchaAnswer = sReceivedResponse;
      },
      'expired-callback': () => {
        this.sCaptchaAnswer = "";
      },
      'error-callback': () => {
        this.sCaptchaAnswer = "";
      }
    });
  }
  
  resetContactUsForm(oFormData: any) {
    this.sCaptchaAnswer = "";
    oFormData.resetForm();
    this.currentUsersFeedback = new userFeedback();    
    window['grecaptcha'].reset();
  }

  sendMessageWithCaptchaResults(oFormData: any) {
    var sEncryptUserData: string = this.app.b64EncodeUnicode(JSON.stringify(oFormData.form.value));
    var sUrl = this.app.sApiServer + "sendMail.php?host=" + this.app.sHostName;
    this.app.spinnerWaiting(true, "captcha_action");
    var aPostData = {"ga": this.sCaptchaAnswer,"ec": sEncryptUserData};
    this.app.mainService.getJSON_POST(sUrl, aPostData).subscribe((aAnswer: any) => {
      this.resetContactUsForm(oFormData);
      this.app.spinnerWaiting(false, "captcha_action");
      if ("object" === typeof aAnswer && "undefined" !== typeof aAnswer.sent && "undefined" !== typeof aAnswer.comment) {
        if (true == aAnswer.sent) {
          this.app.dlgAlert(this.app.langData.defdata.status, this.app.langData.widgets.contactus.message_sent, 1);
          console.log(aAnswer.comment);
        } else {
          this.app.dlgAlert(this.app.langData.defdata.status, this.app.langData.widgets.contactus.message_wasnt_sent, 3);
          console.log(aAnswer.comment);
        }
      } else {
        this.app.dlgAlert(this.app.langData.defdata.status, this.app.langData.widgets.contactus.message_wasnt_sent, 3);
        console.log(aAnswer);
      }
    }); 
  }

  submitContactUsForm(oFormData: any) {
    if ("" !== this.sCaptchaAnswer) {
      this.sendMessageWithCaptchaResults(oFormData);
    } else {
      this.app.dlgAlert(this.app.langData.defdata.status, this.app.langData.defdata.check_captcha, 2);
    }
  }

  ngOnInit() {
    this.addReCaptcha();
  }

}
