import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { XFooterComponent } from './x-footer/x-footer.component';
import { XHeaderComponent } from './x-header/x-header.component';

// Managers
import { managerWgt } from './z-a-manager-wgt/z-a-manager-wgt.component';
import { managerPnl } from './z-a-manager-pnl/z-a-manager-pnl.component';

// Services
import { MainService } from './z-services/main.service';

// Pages
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { ContactsComponent } from './contacts/contacts.component';

// Dialogs
import { dlgAlert } from './z-dlg-alert/z-dlg-alert.component';

// Widgets
import { wgtContactus } from './z-wgt-contactus/z-wgt-contactus.component';
import { wgtCarousel } from './z-wgt-carousel/z-wgt-carousel.component';

// Panels
import { pnlDefault } from './z-pnl-default/z-pnl-default.component';
import { pnlNone } from './z-pnl-none/z-pnl-none.component';
import { pnlDefaultUnbordered } from './z-pnl-default-unbordered/z-pnl-default-unbordered.component';
import { pnlHighTitle } from './z-pnl-high-title/z-pnl-high-title.component';
import { pnlProfileCard4 } from './z-pnl-profile-card4/z-pnl-profile-card4.component';

const appRoutes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'about', component: AboutComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: ':lang', component: IndexComponent},
  {path: ':lang/about', component: AboutComponent},
  {path: ':lang/products', component: ProductsComponent},
  {path: ':lang/contacts', component: ContactsComponent},
  {path: '**', redirectTo: ''}
  //{path: '**', component: NotfoundComponent}
]

@NgModule({
  declarations: [
    managerWgt,
    managerPnl,
    AppComponent,
    XFooterComponent,
    XHeaderComponent,
    IndexComponent,
    AboutComponent,
    ProductsComponent,
    ContactsComponent,
    dlgAlert,
    wgtContactus,
    wgtCarousel,
    pnlDefault,
    pnlNone,
    pnlDefaultUnbordered,
    pnlHighTitle,
    pnlProfileCard4    
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule
  ],
  providers: [MainService],
  bootstrap: [AppComponent],
  entryComponents: [dlgAlert]
})
export class AppModule { }
