import { Component, OnInit } from '@angular/core';

import { MainService } from './z-services/main.service';

import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Inject }  from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { dlgAlert } from './z-dlg-alert/z-dlg-alert.component';

export class User {
  public name: string;
  public email: string;
  public password: string;
  public hobbies: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  curPage: string;
  allDataLoadedVars = [];
  allDataLoaded: Boolean = false;
  allProcessesDoneVars = [];
  allProcessesDone: Boolean = false;
  selLang: string = "";
  defLang: string = "en";
  browserLang: string;
  mainName: string = "GLT Design";
  tempLang: any;
  langData: any;
  langDataClear: any;
  langsAvailable: Array<{code: string, lang_lang: string}> = [];
  sApiServer: string;
  sHostName: string = "";

  model = new User();

  constructor( public mainService: MainService,
               private router: Router,
               private route: ActivatedRoute,
               private titleService: Title,
               private domSanitizer: DomSanitizer,
               @Inject(DOCUMENT) document,
               public matDialog: MatDialog ) {  
    this.selLang=this.getCookie("selLang");
    this.sHostName = window.location.hostname;
    this.setEnvParams();
    //console.log(this.route);
    if("" == this.selLang) {
      this.selLang = navigator.language.substr(0,2).toLowerCase(); 
    }
    this.browserLang = window.location.pathname.substr(1,2);
    this.langRequest();
  }
  
  b64EncodeUnicode(str: any) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    // function toSolidBytes(match, p1) {
    (match, p1) => {
      // console.debug('match: ' + match);
      return String.fromCharCode(("0x" + p1) as any);
    }));
  }
  
  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  
  bypassHtmlSanitizing(sHtml: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(sHtml);
  }

  clearCookie(cname: string = "")
  {
    let ret: boolean = false;
    let qLen=cname.length;
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let key: string;
    for (let i: number = 0; i < caLen; i += 1) 
    {
      key = ca[i].replace(/^\s+/g, '');      
      key=key.substr(0,key.search("="));
      if(qLen==0)
      {
        this.setCookie(key,"",0);
        ret = true;        
      }
      else
      {
        if(key==cname)
        {
          this.setCookie(key,"",0);
          return true;          
        }
      }      
    }
    return ret;
  }

  getCookie(cname: string = "") {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }  

  getCookieInt(cname: string = ""): number {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return parseInt(c.substring(name.length, c.length),10);
      }
    }
    return null;
  }  

  getCookieList()
  {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let key: string;
    var clist: Array<string> = new Array();
    for (let i: number = 0; i < caLen; i += 1) 
    {
      key = ca[i].replace(/^\s+/g, '');
      key=key.substr(0,key.search("="));
      clist.push(key);
    }
    return clist;
  }

  langRequest() {
    this.browserLang;
    this.spinnerLoader(false,"lang");
    this.langsAvailable = [];
    this.langData = this.langDataClear;
    
    var urlVar = this.sApiServer + "lang.php";
    this.mainService.getJSON_GET(urlVar + "?act=getAvailableLanguages").subscribe((retData: any[]) => {
      var sDetectedLang: string = "";
      for (let i = 0; i < retData.length; i++) {
        var sLangCode: string = retData[i].code;
        this.langsAvailable.push({ code: sLangCode, lang_lang: retData[i].lang_lang });
        if ("" != this.browserLang && this.browserLang == sLangCode) {
          sDetectedLang = sLangCode;
        }
      }  

      if ("" == sDetectedLang) {
        for (let i = 0; i < this.langsAvailable.length; i++) {
          if (this.langsAvailable[i].code == this.selLang) {
            sDetectedLang = this.selLang;
          }
        }
      }
         
      if ("" == sDetectedLang) {
        this.selLang = this.defLang;
      } else {
        this.selLang = sDetectedLang;
      }

      this.setCookie("selLang", this.selLang);
      this.mainService.getJSON_GET(urlVar + "?act=getLanguageData&lang="+this.getCookie("selLang")).subscribe((retData: any[]) => {
        for (let i = 0; i < retData.length; i++) {
          var sLangCode: string = retData[i].code;
          if (this.selLang == sLangCode) {
            this.langData = retData[i].data;
            this.setTitle();
            this.spinnerLoader(true,"lang");
          }
        }
        this.tempLang = retData;
      });
    }); 
  }

  dlgAlert(sTitle: string, sMessage: string, iDlgType: number) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "dlgAlertBox";
    // dialogConfig.height = "350px";
    // dialogConfig.width = "600px";
    dialogConfig.data = {"title": sTitle, "msg": sMessage, "closeBtn": this.langData.defdata.close, "type": iDlgType};
    const modalDialog = this.matDialog.open(dlgAlert, dialogConfig);
  }

  setCookie(cname: string, cvalue: string, exmin: number=0) {
    var d = new Date();
    if(exmin==0) {
      //365 days session expire
      exmin=365*24*60;
    }
    d.setTime(d.getTime() + (exmin*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  setTitle() {
    this.titleService.setTitle(this.langData.pages[this.curPage].title+" :: "+this.langData.defdata.title);    
  }

  setEnvParams() {
    switch (this.sHostName) {
      case "localhost":
        this.sApiServer = "http://devapi.glt.md/"
        break;
      case "glt.ro":
        this.sApiServer = "https://api.glt.ro/"
        break;
      case "glt.md":
        this.sApiServer = "https://api.glt.md/"
        break;
      case "glt-ltd.com":
        this.sApiServer = "https://api.glt-ltd.com/"
        break;
      default:
        this.sApiServer = "https://api.glt.md/";
        break;
    }
  }

  spinnerLoader(act: boolean, code: string) {
    if (act) {
      this.allDataLoadedVars=this.allDataLoadedVars.filter(item => item !== code);
    } else {
      this.allDataLoadedVars.push(code);
    }
    if (this.allDataLoadedVars.length==0) {
      this.allDataLoaded = true;
    } else {
      this.allDataLoaded = false;
    }
  }

  spinnerWaiting(act: boolean, code: string) {
    if (act) {
      this.allProcessesDoneVars=this.allProcessesDoneVars.filter(item => item !== code);
    } else {
      this.allProcessesDoneVars.push(code);
    }
    if (this.allProcessesDoneVars.length==0) {
      this.allProcessesDone = true;
    } else {
      this.allProcessesDone = false;
    }
  }
  postId: any;

  ngOnInit() { 
    this.spinnerLoader(false,"path");  
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        //console.log(this.route.snapshot.children[0].routeConfig.path);
        if(this.route.snapshot.children[0].routeConfig.path.substr(0,1)!=":"){
        this.router.navigate([this.selLang+"/"+this.router.url.substr(1)]);
      }
      let url=this.route.snapshot.children[0].routeConfig.path.substr(6).split("/:")[0]; 
      this.curPage=(url!=""?url:"index");
      if(this.langData!=null) this.setTitle();
      this.spinnerLoader(true,"path");  
    });
  }

}
