import { Component, OnInit, Input } from '@angular/core';

import { AppComponent } from '../app.component';

@Component({
  selector: 'manager-wgt',
  templateUrl: './z-a-manager-wgt.component.html',
  styleUrls: ['./z-a-manager-wgt.component.css']
})
export class managerWgt implements OnInit {

  @Input() aWidgetParams: any;

  constructor( public app: AppComponent ) { }

  ngOnInit() {
  }
  
}
